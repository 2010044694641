import { firebase, auth } from '@/components/utils/firebase'
import { businessDB } from '@shared/database'
import { debounce } from '@/components/utils/debounce'

export const state = () => ({
  hasSetupCompleted: false,
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  companyId: null,
  isAdmin: false,
  permission: null,
  subscriptionEnder: null,
  isAnonymous: null,
  updateOrdersUnsubscribe: null,
  updateMessageRoomsUnsubscribe: null,
  updateMessagesUnsubscribe: null,
  updateCompanyDepositUnsubscribe: null,
  currentPoint: 0,
  availablePoint: 0
})

export const mutations = {
  updateUid(state, value) {
    state.uid = value
  },
  updateUserDisplayName(state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating(state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn(state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut(state, value) {
    state.isSigningOut = value
  },
  async updateAuthStatus(state, user) {
    if (user && !state.isLoggedIn) {
      try {
        debounce(this, saveSignInTime(user), 10000)
      } catch (error) {
        console.log(error)
      }
    }

    if (user) {
      const result = await auth.currentUser.getIdTokenResult(false)
      const claims = result.claims ? result.claims : null
      if (claims) {
        state.companyId = claims.businessCompanyId
        state.isAdmin = claims.businessPermission === 'admin'
        state.permission = claims.businessPermission
      } else {
        state.companyId = null
        state.isAdmin = false
      }
    }

    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
    state.hasSetupCompleted = true
  },
  resetState(state) {
    state.uid = null
    state.companyId = null
    state.userDisplayName = null
    state.userAuthEmail = null
    state.isLoggedIn = false
    state.isAdmin = false
    state.isAuthenticating = true
    state.isSigningOut = false
    state.isAnonymous = null
    state.updateMessagesUnsubscribe = null
  },
  updatePermission(state, value) {
    state.isAdmin = value === 'admin'
    state.permission = value
  },
  updateSubscriptionEnder(state, value) {
    state.subscriptionEnder = value
  },
  setUpdateOrdersUnsubscribe(state, value) {
    state.updateOrdersUnsubscribe = value
  },
  setUpdateMessageRoomsUnsubscribe(state, value) {
    state.updateMessageRoomsUnsubscribe = value
  },
  setUpdateMessagesUnsubscribe(state, value) {
    state.updateMessagesUnsubscribe = value
  },
  updateCompanyId(state, value) {
    state.companyId = value
  },
  updateCurrentPoint(state, value) {
    state.currentPoint = value
  },
  updateAvailablePoint(state, value) {
    state.availablePoint = value
  },
  setUpdateCompanyDepositUnsubscribe(state, value) {
    state.updateCompanyDepositUnsubscribe = value
  }
}

export const actions = {
  setupAuthStateHandler({ commit, state }) {
    const shouldSetupAuthstate =
      !state.isLoggedIn && state.isAuthenticating && !state.isSigningOut
    if (shouldSetupAuthstate) {
      const ender = firebase.auth().onAuthStateChanged((user) => {
        commit('updateAuthStatus', user)
      })

      commit('updateSubscriptionEnder', ender)
    }
  },
  endSubscription({ state, commit }) {
    if (state.subscriptionEnder) {
      state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async signOut({ state, commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      dispatch('endSubscription')
      if (state.updateWorksUnsubscribe) state.updateWorksUnsubscribe()
      if (state.updateCardsUnsubscribe) state.updateCardsUnsubscribe()
      if (state.updateSumUnsubscribe) state.updateSumUnsubscribe()
      if (state.updateOrdersUnsubscribe) state.updateOrdersUnsubscribe()
      if (state.updateMessageRoomsUnsubscribe)
        state.updateMessagesRoomsUnsubscribe()
      if (state.updateMessagesUnsubscribe) state.updateMessagesUnsubscribe()
      await firebase.auth().signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
  },
  setUnsubscribeUpdateOrders({ commit }, value) {
    commit('setUpdateOrdersUnsubscribe', value)
  },
  setUnsubscribeUpdateMessageRooms({ commit }, value) {
    commit('setUpdateMessageRoomsUnsubscribe', value)
  },
  setUnsubscribeUpdateMessages({ commit }, value) {
    commit('setUpdateMessagesUnsubscribe', value)
  },
  subscribeToCompanyDeposit({ commit, state }) {
    if (!state.companyId) {
      return
    }

    const unsubscribe = businessDB
      .businessCompanySecretCollection(state.companyId)
      .subscribeCompanyDeposit((depositData) => {
        if (!depositData) return
        const currentPoint = depositData.amount
        const availablePoint = depositData.availableAmount

        commit('updateCurrentPoint', currentPoint)
        commit('updateAvailablePoint', availablePoint)
      })

    // Vuex stateにunsubscribe関数を保存
    commit('setUpdateCompanyDepositUnsubscribe', unsubscribe)
  },
  // Firebaseのリスナーを解除する
  unsubscribeFromCompanyDeposit({ state, commit }) {
    if (state.updateCompanyDepositUnsubscribe) {
      state.updateCompanyDepositUnsubscribe()
      commit('setUpdateCompanyDepositUnsubscribe', null)
    }
  }
}

async function saveSignInTime(user) {
  try {
    await businessDB.businessUserCollection().update(user.uid, {
      lastSignInTime: new Date()
    })
  } catch (error) {
    console.log(error)
  }
}
